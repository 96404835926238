import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface ModalTallerEditProps {
  show: boolean;
  onHide: () => void;
}
const ModalTallerEditComponents: React.FC<ModalTallerEditProps> = ({ show, onHide }) => {
 
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter">
        
          <h2 className="text-40 text-center text-500 ">¿Estás segura de reagendar este taller?</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-24 text-center">Una vez que cambies la fecha, las mujeres inscritas recibirán la notificación en el chat de WhatsApp y es probable que algunas de ellas no puedan asistir y tengan que cambiar de fecha</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-1">
          <a href="/edicion-de-taller"  className="btn btn--type1">Reagendar taller</a>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn--simple text-purple500" onClick={onHide}>No quiero cambiar este taller</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTallerEditComponents;
