import React, { useEffect, useState } from 'react';
import { Button, Input, MessageBox, MessageBoxType } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { io, Socket } from 'socket.io-client';
import Modal from 'react-bootstrap/Modal';
import { IAdmin } from '../../types/adminInterface';

interface ChatComponentProps {
  admin: Partial<IAdmin>;
  roomId: string;
  token: string;
  messages: MessageBoxType[];
  setNewMessage?: (msg: MessageBoxType) => void;
  show: boolean;
  onHide: () => void;
}

interface roomMessage {
  room?: string;
  msg: string;
  sender: string;
  transactionId: number;
  senderName: string;
}

const ChatModalComponent: React.FC<ChatComponentProps> = ({
  admin,
  roomId,
  messages,
  setNewMessage,
  show,
  onHide,
}) => {
  const [socket, setSocket] = useState<Socket>();
  const [inputValue, setInputValue] = useState('');

  const handleSendMessage = () => {
    if (inputValue.trim() !== '') {
      console.log('Room ID:', admin);
      const randomTransactionId = Math.floor(Math.random() * 1000000);
      if (socket) {
        socket.emit('roomMessage', {
          room: roomId,
          msg: inputValue,
          sender: admin._id,
          transactionId: randomTransactionId,
          senderName: admin.username || 'Anónimo',
        });
        pushNewMessage(
          inputValue,
          'right',
          admin._id || '',
          randomTransactionId,
          admin.username || 'Anónimo'
        );
        setInputValue('');
      }
    }
  };

  const pushNewMessage = (
    msg: string,
    position: string,
    sender: string,
    transactionId: number,
    senderName: string
  ) => {
    console.log('Received message:', { msg, messages: messages.length });
    const newMessage: MessageBoxType = {
      id: transactionId,
      position,
      type: 'text',
      text: msg,
      date: new Date(),
      avatar:
        sender === admin._id
          ? '/assets/images/icons/user-small.svg'
          : '/assets/images/icons/user-pic-2.svg',
      title: senderName,
      focus: false,
      forwarded: false,
      replyButton: false,
      removeButton: false,
      titleColor: '#000000',
      status: 'sent',
      notch: true,
      retracted: false,
    };
    // add to the beginning of the array
    setNewMessage ? setNewMessage(newMessage) : null;
    setInputValue('');
  };

  const noRepeatedMessages = (messages: MessageBoxType[]) => {
    const uniqueMessages = messages.filter(
      (msg, index, self) => index === self.findIndex((m) => m.id === msg.id)
    );
    return uniqueMessages;
  };

  useEffect(() => {
    const socket = io('https://chat.conlasamigas.org/');
    setSocket(socket);
    socket.emit('joinRoom', roomId);

    socket.on('roomMessage', (data: roomMessage) => {
      console.log('Received roomMessage message:', { data, admin });
      if (data.sender !== admin._id) {
        pushNewMessage(
          data.msg,
          'left',
          data.sender,
          data.transactionId,
          data.senderName
        );
      }
    });
    setTimeout(() => {
      const chatContainer = document.querySelector('.rce-mlist');
      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 200,
          behavior: 'smooth',
        });
      }
    }, 500);
  }, [admin]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <h2 className="text-40 text-center text-500 pb-2">Chat</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className="chatbox-placeholder ">
          <div className="chat-container">
            <div className="rce-container-mlist message-list">
              <div className="rce-mlist">
                {noRepeatedMessages(messages).map((msg) => (
                  <MessageBox
                    key={msg.id}
                    id={msg.id.toString()}
                    position={msg.position}
                    type={msg.type as 'text'} // Casting para asegurar que el tipo es 'text'
                    text={msg.text}
                    date={msg.date}
                    avatar={msg.avatar}
                    title={msg.title}
                    focus={msg.focus}
                    titleColor={msg.titleColor}
                    forwarded={msg.forwarded}
                    replyButton={msg.replyButton}
                    removeButton={msg.removeButton}
                    status={msg.status}
                    notch={msg.notch}
                    retracted={msg.retracted}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="chatbox__send">
            <Input
              placeholder="Escribe tu mensaje..."
              multiline={false}
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputValue(e.target.value)
              }
              maxHeight={100}
              rightButtons={
                <Button
                  color="white"
                  backgroundColor="#845DB6"
                  text="Enviar"
                  onClick={handleSendMessage}
                />
              }
            />
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default ChatModalComponent;
