import React from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import TalleresComponent from '../../../components/Elements/Talleres/talleres.component';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'


interface Item {
  id: number;
  name: string;
}

const TalleresScreen: React.FC = () => {
  // note: the id field is mandatory
  const items = [
    {
      id: 0,
      name: 'Taller 1'
    },
    {
      id: 1,
      name: 'Taller 2'
    },
    {
      id: 2,
      name: 'Taller 3'
    },
    {
      id: 3,
      name: 'Taller 4'
    },
    {
      id: 4,
      name: 'Taller 5'
    }
  ]

  const handleOnSearch = (string: string, results: Item[]) => {
    console.log(string, results);
  }

  const handleOnSelect = (item: Item) => {
    // the item selected
    console.log(item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const handleOnHover = (result: Item) => {
    // the item hovered
    console.log(result)
  }

  const formatResult = (item: Item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }


  return (
    <>
    <HeaderShared />
    <section className="module container">
      <div className="body-row row">
        <div className="col-12">
         
          <div className="row">
           
            <div className="col-12 mb-5">
              <h1 className='mb-4'>Tus talleres</h1>
              <p>Puedes buscar por: <strong>Nombre, Hora, Fecha</strong></p>
              <div className="form-search-complete">
                <div className="autocomplete-search">
                  <ReactSearchAutocomplete
                    items={items}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    autoFocus
                    formatResult={formatResult}
                    placeholder="Buscar talleres"
                    className="autocomplete-search__input"
                  />
                </div>
                <a href="/crear-taller" className="btn btn--type1">+ Registrar un nuevo taller</a>
              </div>
            </div>
            <div className="talleres-col col-12 mb-4">
              <h2 className="h4 mb-3">Talleres próximos a impartir</h2>
              <TalleresComponent
                mode="ver-mas"
                showAvailableSlotsBadge={false}
                showStockList={false}
                justifyContentClass="justify-content-start"
                cols="col-xl-3"
              />
            </div>
            <div className="talleres-col col-12  mb-4">
              <h2 className="h4 mb-3">Talleres reagendados</h2>
              <TalleresComponent
                mode="ver-mas"
                showAvailableSlotsBadge={false}
                showStockList={false}
                justifyContentClass="justify-content-start"
                cols="col-xl-3"
                tallerEstado="reagendados"
              />
            </div>
           
            <div className="talleres-col col-12">
              <h2 className="h4 mb-3">Talleres concluídos</h2>
              <TalleresComponent
                mode="ver-mas"
                showAvailableSlotsBadge={false}
                showStockList={false}
                justifyContentClass="justify-content-start"
                cols="col-xl-3"
                tallerEstado="concluidos"
              />
            </div>
           
          </div>
        </div>
      </div>
    </section>
    <FooterShared />
  </>
  );
};

export default TalleresScreen;