import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import TallerItemComponent from '../../../../components/Elements/Talleres/taller.component';


const TalleresConfirmScreen: React.FC = () => {

  return (
    <>
    <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
           
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a href="/talleres" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
              </div>
              <div className="col-md-5 col-12">
                <h1>Confirma los datos del taller</h1>
                <p className="text-20">Así verán tu taller las mujeres de la plataforma. Confirma si los datos están correctos para dar de alta tu taller.</p>
                <div className="row justify-content-center create-taller-item">
                  <TallerItemComponent />
                </div>
                <div className="row justify-content-center ">
                  <div className="col-12 col-reset mb-4">
                    <a href="/creacion-finalizada" className="btn btn--type1 btn--100">Terminar registro</a>
                  </div>
                  <div className="col-12 col-reset">
                    <a href="/crear-taller" className="btn btn--type2 btn--100">Editar información</a>
                  </div>
                  <div className="col-12 col-reset">
                    <a href="/talleres" className="btn btn--danger btn--100">Borrar taller</a>
                  </div>
                </div>
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img src="/assets/images/illustrations/remember.png" alt="Editar taller" />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
  </>
  );
};

export default TalleresConfirmScreen;