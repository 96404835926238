import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import TalleresComponent from '../../Talleres/talleres.component';

interface ModalTallerProps {
  show: boolean;
  onHide: () => void;
  headerTitle?: string;
  headerSubtitle?: string; 
  showSubmitButton?: boolean; 
}
const ModalTallerComponents: React.FC<ModalTallerProps> = ({
  show,
  onHide,
  headerTitle = "Derivar a otro taller", // Valor por defecto
  headerSubtitle = "Selecciona entre los talleres disponibles", // Valor por defecto
  showSubmitButton = false, // Muestra el botón por defecto
}) => {

  const [talleresSeleccionados, setTalleresSeleccionados] = useState<number[]>([]);

  const handleSelectionChange = (selectedIds: number[]) => {
    setTalleresSeleccionados(selectedIds);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--derivar"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">{headerTitle}</h2>
          <p className="text-24 text-center">{headerSubtitle}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TalleresComponent mode={'seleccionar'} onSelectionChange={handleSelectionChange}/>
      </Modal.Body>
      <Modal.Footer>
      {showSubmitButton && (
          <div className="col-12 d-flex justify-content-center mb-3">
            <button
              type="button"
              className="btn btn--type1 btn--370"
              onClick={onHide}
              disabled={talleresSeleccionados.length === 0} // Habilitado si hay al menos un taller seleccionado
            >Enviar selección de talleres</button>
          </div>
        )}
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn--type3 btn--370" onClick={onHide}>Cerrar</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTallerComponents;
