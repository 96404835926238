export const TALLERES_DATA = [
  {
    "id": 1,
    "tipo": "online",
    "cupoDisponible": 50,
    "cupoTotal": 50,
    "fecha": "Sábado 30 de marzo, 2023",
    "hora": "17:00 hrs",
    "imagen": "/assets/images/illustrations/remember.png"
  },
  {
    "id": 2,
    "tipo": "presencial",
    "cupoDisponible": 5,
    "cupoTotal": 50,
    "fecha": "Sábado 30 de marzo, 2023",
    "hora": "17:00 hrs",
    "imagen": "/assets/images/illustrations/remember.png"
  }
]

