import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';

interface PersonalDataProps {
  user: Partial<IUser>;
}

const PersonalDataComponent: React.FC<PersonalDataProps> = ({ user }) => {
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});

  useEffect(() => {
    if (user) {
      setPersonalData(user);
    }
  }, [user]);
  return (
    <div className="form-block">
      <form>
        <div className="form-row">
          <label htmlFor="name" className="form-label label--icon">
            Nombre
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nombre de usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Nombre del usuario"
            readOnly={true}
            value={personalData.username || ''}
          />
        </div>
        <div className="form-row">
          <label htmlFor="email" className="form-label label--icon">
            Correo
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Esta información es importante para poder tener un medio de
                  contacto con el usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="tucorreo@dominio.com.cl"
            readOnly={true}
            value={personalData.email || ''}
          />
        </div>
        <div className="form-row">
          <label htmlFor="phone" className="form-label label--icon">
            Teléfono
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Esta información es importante para mantenernos en contacto a
                  través de llamadas y/o chats.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="phone"
            className="form-control"
            id="phone"
            placeholder="+1234567890"
            readOnly={true}
            value={personalData.phone || ''}
          />
        </div>
      </form>
    </div>
  );
};

export default PersonalDataComponent;
