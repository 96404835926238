import React, {useState, useEffect} from 'react';
import DataTable, { TableColumn }  from 'react-data-table-component';
import CustomPagination from '../Paginator/paginator.component'
import { Accordion } from 'react-bootstrap';
interface DataRow {
  id: number;
  ficha: string;
  name: string;
  weeks: string;
  days: string;
  derivada: string;
  apuntes: string;
  status: string;
  url: string;
  isNew: boolean;
  acompanante: string;
}

const AsistenciaTableComponent: React.FC = () => {

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setRows(rows.map(row => {
      if (row.id === id) {
        // Actualiza el estado 'status' basado en si el checkbox está marcado
        return { ...row, status: isChecked ? 'asistio' : 'no asistio' };
      }
      return row;
    }));
  };
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);
  const [rows, setRows] = useState<DataRow[]>([
    {
      id: 1,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 2,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 3,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'no-asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 4,
      ficha: '12894',
      name: 'Maria',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 5,
      ficha: '12894',
      name: 'Ana',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 6,
      ficha: '12894',
      name: 'Laura',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'no-asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 7,
      ficha: '12894',
      name: 'Sofia',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 8,
      ficha: '12894',
      name: 'Isabella',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 9,
      ficha: '12894',
      name: 'Valentina',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'no-asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 10,
      ficha: '12894',
      name: 'Camila',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 11,
      ficha: '12894',
      name: 'Luciana',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 12,
      ficha: '12894',
      name: 'Gabriela',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'no-asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 13,
      ficha: '12894',
      name: 'Mariana',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 14,
      ficha: '12894',
      name: 'Daniela',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 15,
      ficha: '12894',
      name: 'Fernanda',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'no-asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 16,
      ficha: '12894',
      name: 'Carolina',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 17,
      ficha: '12894',
      name: 'Valeria',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 18,
      ficha: '12894',
      name: 'Juliana',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'no-asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 19,
      ficha: '12894',
      name: 'Antonella',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 20,
      ficha: '12894',
      name: 'Luisa',
      weeks: '10',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    }
   
  ]);

  const handleNameClick = (id: number) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, isNew: false };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: 'No. Ficha',
      selector: (row: DataRow) => row.ficha,
    },
    {
      name: 'Nombre',
      selector: (row: DataRow) => row.name,
      format: (row: DataRow) => {
        return (
          <a href={row.url} 
             className={`text-purple500 text-uppercase ${row.isNew ? 'new' : ''}`}
             onClick={() => handleNameClick(row.id)}>
            <strong>{row.name}</strong>
          </a>
        );
      }
    },
    {
      name: 'Semanas',
      selector: (row: DataRow) => row.weeks,
      format: (row: DataRow) => {
        return (
          <div className="data-table__input">
            <input type="number" className="form-control form--medium form--center" value={row.weeks} />
            <p>+</p>
            <input type="number" className="form-control form--medium form--center" value={row.days} />
            <p>días</p>
          </div>
        );
      }
      
    },
    {
      name: 'Derivada de',
      selector: (row: DataRow) => row.derivada,
    },
    {
      name: 'Acompañante',
      selector: (row: DataRow) => row.acompanante,
    },
    {
      name: 'Apuntes',
      selector: (row: DataRow) => row.apuntes,
      format: (row: DataRow) => {
        return (
         <select className="form-control form-select form--medium" defaultValue={row.apuntes}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
         </select>
        );
       
      }
    },
    {
      name: 'Estado',
      selector: (row: DataRow) => row.status,
      format: (row: DataRow) => (
        <div className="form-check">
          <input 
            className="form-check-input" 
            type="checkbox" 
            value={row.status} 
            id={`estado-${row.id}`}
            checked={row.status === 'asistio'}
            onChange={(e) => handleCheckboxChange(row.id, e.target.checked)} // Usa el evento para actualizar el estado
          />
          <label className="form-check-label" htmlFor={`estado-${row.id}`}>
            {row.status === 'asistio' ? 'Asistió' : 'No Asistió'}
          </label>
        </div>
      )
    },
    
  ];
  
  const conditionalRowStyles = [
    {
      when: (row: DataRow) => row.isNew,
      style: {
        backgroundColor: '#FCEEF2',
      }
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };



  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table">
      {!isMobileView ? (
        <DataTable
          columns={columns}
          data={rows}
          conditionalRowStyles={conditionalRowStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationComponent={props => {
            const handleChangePage = (page: number) => {
              console.log('Changing page to', page);
              // Asume que props.onChangePage realmente espera un solo argumento.
              // Esto es para diagnóstico; revisa la consola para ver qué se registra cuando cambias de página.
              (props.onChangePage as (page: number) => void)(page);

            };
          
            return (
              <div className="data-paginator">
                <CustomPagination
                  rowsPerPage={props.rowsPerPage} // eslint-disable-next-line react/prop-types
                  rowCount={props.rowCount} // eslint-disable-next-line react/prop-types
                  onChangePage={handleChangePage}
                  currentPage={currentPage}
                />
              </div>
            );
          }}
          
          onChangePage={page => setCurrentPage(page)}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={rows.length}
        />

      ): (
        <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p><strong>Nombre</strong></p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {rows.map((row, index) => (
                <Accordion.Item eventKey={String(index)} key={row.id}>
                  <Accordion.Header>{row.name}</Accordion.Header>
                  <Accordion.Body>
                    <table>
                      <tr>
                        <td><strong>No. Ficha:</strong></td>
                        <td className="text-center">{row.ficha}</td>
                      </tr>
                      <tr>
                        <td><strong>Semanas:</strong></td>
                        <td className="text-center">
                          <div className="data-table__input">
                              <input type="number" className="form-control form--medium form--center" value={row.weeks} disabled/>
                              <p>+</p>
                              <input type="number" className="form-control form--medium form--center" value={row.days} disabled/>
                              <p>días</p>
                            </div>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Derivada de:</strong></td>
                        <td className="text-center">{row.derivada}</td>
                      </tr>
                      <tr>
                        <td><strong>Acompañante:</strong></td>
                        <td className="text-center">{row.acompanante}</td>
                      </tr>
                      <tr>
                        <td><strong>Apuntes:</strong></td>
                        <td className="text-center">
                        <select className="form-control form-select form--medium" defaultValue={row.apuntes}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Estado:</strong></td>
                        <td className="text-center">
                        <div className="form-check">
                            <input 
                              className="form-check-input" 
                              type="checkbox" 
                              value={row.status} 
                              id={`estado-${row.id}`}
                              checked={row.status === 'asistio'}
                              onChange={(e) => handleCheckboxChange(row.id, e.target.checked)} // Usa el evento para actualizar el estado
                            />
                            <label className="form-check-label" htmlFor={`estado-${row.id}`}>
                              {row.status === 'asistio' ? 'Asistió' : 'No Asistió'}
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-center">
                          <a href="/detalle-usuario" className="btn btn--type2">
                            <strong>Ver ficha</strong>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
        </section>
      )}
      
    </div>
  );
};

export default AsistenciaTableComponent;
