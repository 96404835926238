import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';

const TallerCreateSuccessScreen: React.FC= () => {
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            

            <div className="module-bottom row">
              <div className="col-lg-6 col-12 mx-auto text-center">
                <div className="d-flex justify-content-center">
                  <figure className="image-490">
                    <img src="/assets/images/illustrations/cat.png" alt="Confirmación" />
                  </figure>
                </div>
                <h1 className="text-40 mb-3">¡ Nuevo taller creado !</h1>
                <p>Se registró un nuevo taller para el <strong>sábado 30 de abril 2024 a las 17:00 hrs</strong> de manera exitosa, las mujeres ya se podrán inscribir.</p>

                
              </div>
            </div>
          <div className="form-row">
           <div className="col-12 mb-4 d-flex justify-content-center">
            <a href="/talleres"
                className="btn btn--type1 btn--370"
              >Ir a talleres</a>
           </div>
           <div className="col-12 d-flex justify-content-center">
            <a href="/"
                className="btn btn--type2 btn--370"
              >Crear grupo de Whatsapp</a>
           </div>

          </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  )
}

export default TallerCreateSuccessScreen;
