import React from 'react';
import HeaderAdminShared from '../../../../shared/Header/headerAdmin.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import { Accordion } from 'react-bootstrap';
import PersonalDataComponent from '../../../../components/Forms/userDetail/personalData.component';
import DemographicDataComponent from '../../../../components/Forms/userDetail/demographicData.component';
import HealthDataComponent from '../../../../components/Forms/userDetail/healthData.component';
import InfoDataComponent from '../../../../components/Forms/userDetail/infoData.component';

interface EditUserScreenProps {
  // Define your component props here
}

const EditUserScreen: React.FC<EditUserScreenProps> = () => {
  // Add your component logic here

  return (
    <>
      <HeaderAdminShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a href="/detalle-usuario" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-md-5 col-12">
                <h1>Editar información</h1>
                <Accordion defaultActiveKey="0" className="accordion-regular">
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Información personal</Accordion.Header>
                    <Accordion.Body>
                      <PersonalDataComponent user={{}} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Datos Sociodemográficos</Accordion.Header>
                    <Accordion.Body>
                      <DemographicDataComponent user={{}} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Salud y Cuidados</Accordion.Header>
                    <Accordion.Body>
                      <HealthDataComponent user={{}} token="token" />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Contexto</Accordion.Header>
                    <Accordion.Body>
                      <InfoDataComponent user={{}} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="col-12 mt-3">
                  <a
                    href="/editar-usuario-finalizado"
                    className="btn btn--type1 btn--100"
                  >
                    Guardar cambios
                  </a>
                </div>
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-280">
                  <img
                    src="/assets/images/illustrations/about.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default EditUserScreen;
