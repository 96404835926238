import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';
import { format } from 'date-fns';
import { getUserFile } from '../../../api/usersApi';

interface HealthDataProps {
  user: Partial<IUser>;
  token: string;
}

const HealthDataComponent: React.FC<HealthDataProps> = ({ user, token }) => {
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});

  useEffect(() => {
    if (user) {
      setPersonalData(user);
    }
  }, [user]);

  const getUserFiles = (user: Partial<IUser>) => {
    const createdAt = new Date(user.saludGinecologica?.createdAt || '');
    const ultrasoundReportFile =
      user.saludGinecologica?.ultrasoundReportFile || [];
    const additionalFiles = user.saludGinecologica?.additionalFiles || [];
    return [
      ...ultrasoundReportFile.map((f) => ({
        name: f.split('/').pop() || '',
        fullName: f || '',
        date: format(createdAt, 'dd/MM/yyyy'),
        hour: format(createdAt, 'HH:mm'),
      })),
      ...additionalFiles.map((f) => ({
        name: f.split('/').pop() || '',
        fullName: f || '',
        date: format(createdAt, 'dd/MM/yyyy'),
        hour: format(createdAt, 'HH:mm'),
      })),
    ];
  };

  const openFile = async (file: string) => {
    try {
      const url = await getUserFile(`public/${file}`, token);
      window.open(url.fileUrl, '_blank');
    } catch (error) {
      console.error('Error al abrir el archivo:', error);
    }
  };

  const getAllQuestions = () => {
    const sexualidadYEmociones =
      personalData.sexualidadYEmociones?.questions || [];
    const saludGinecologica = personalData.saludYEmociones?.questions || [];

    return [...sexualidadYEmociones, ...saludGinecologica];
  };

  return (
    <div className="form-block">
      <form>
        <div className="form-row">
          <label htmlFor="weeks" className="form-label label--icon">
            Semanas de embarazo al día de hoy
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Semanas de embarazo del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="weeks"
            placeholder="Semanas de embarazo"
            readOnly={true}
            value={
              personalData.saludGinecologica?.pregnancyCalculator?.result || ''
            }
          />
        </div>
        <div className="form-row">
          <div className="row">
            <div className="col-12">
              <label htmlFor="metodo" className="form-label label--icon">
                Método para comprobar
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Método para comprobar de embarazo del usuario
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <input
                type="text"
                className="form-control"
                id="metodo"
                placeholder="Método para comprobar embarazo"
                value={(
                  personalData.saludGinecologica?.whatMethodUse || []
                ).join(', ')}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <label htmlFor="metodo" className="form-label text-20">
            Ecografía
          </label>
          {getUserFiles(personalData).map((file, index) => (
            <div className="image-user" key={index}>
              <div className="pb-3 pt-3">
                <button
                  className="btn btn--type1"
                  onClick={(e) => {
                    e.preventDefault();
                    openFile(file.fullName);
                  }}
                >
                  Ver archivo
                </button>
              </div>
              <div className="image-user__data">
                <i className="icon icon--image"></i>
                <article>
                  <p className="text-18 pb-2">
                    <strong>{file.name}</strong>
                  </p>
                  <p className="text-18">Subida correctamente</p>
                  <p className="text-18">{file.date}</p>
                  <p className="text-18">{file.hour}</p>
                </article>
              </div>
            </div>
          ))}
        </div>
        <div className="form-row">
          <label htmlFor="gestaciones" className="form-label label--icon">
            Gestaciones anteriores
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Gestaciones anteriores del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="gestaciones"
            placeholder="Gestaciones anteriores"
            value={personalData.saludGinecologica?.pregnancies || ''}
          />
        </div>
        <div className="form-row">
          <label htmlFor="intento-aborto" className="form-label label--icon">
            Intentó abortar en este embarazo
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>Intentó abortar en este embarazo del usuario</Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="intento-aborto"
            placeholder="Intentó abortar en este embarazo"
            value={personalData.saludGinecologica?.tryToAbortEarlier || ''}
          />
        </div>
        {getAllQuestions().map((q) => {
          const { id, question, answer, detail, confianzaList } = q;
          return (
            <div className="form-row" key={id}>
              <div className="row">
                <div className="col-6">
                  <label
                    htmlFor={`pregunta-${id}`}
                    className="form-label label--icon"
                  >
                    {question}
                  </label>
                  {answer && (
                    <input
                      type="text"
                      className="form-control"
                      id={`pregunta-${id}`}
                      placeholder="Pregunta"
                      value={answer}
                    />
                  )}
                </div>
                <div className="col-6">
                  {detail && (
                    <div className="form-row">
                      <label htmlFor={`detalle-${id}`} className="form-label">
                        Detalle
                      </label>
                      <textarea
                        className="form-control"
                        id={`detalle-${id}`}
                        placeholder="Detalle"
                        value={detail}
                      />
                    </div>
                  )}
                  {(confianzaList || []).length > 0 && (
                    <div className="form-row">
                      <textarea
                        className="form-control"
                        id={`confianza-${id}`}
                        placeholder="Confianza"
                        value={(confianzaList || []).join(', ')}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default HealthDataComponent;
