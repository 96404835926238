import React from 'react';

interface Props {
}

const TallerItemComponent: React.FC<Props> = () => {

  return (
    <div className="item-workshop__col col-sm-6 col-12">
      <div className="item-workshop">
      <figure className="item-workshop__image">
        <p className="badge badge-label badge--success">
          <span className="icon material-icons-outlined">verified</span>
          <span><small>ONLINE</small></span>
        </p>
        <img src="/assets/images/illustrations/remember.png" alt="Taller" />
      </figure>
      <article>
        <p className="badge badge-label badge--info">
          <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
          <span>50 CUPOS DISPONIBLES</span>
        </p>
        <ul className="stock-list">
          <li>
            <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
          </li>
          <li>
            <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
          </li>
          <li>
            <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
          </li>
          <li>
            <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
          </li>
          <li>
            <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
          </li>
          <li>50 CUPOS</li>
        </ul>
        <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
        <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
      </article>
    </div>
  </div>
  );
};

export default TallerItemComponent;