import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';

interface InfoDataProps {
  user: Partial<IUser>;
}

const InfoDataComponent: React.FC<InfoDataProps> = ({ user }) => {
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});

  useEffect(() => {
    if (user) {
      setPersonalData(user);
    }
  }, [user]);

  return (
    <div className="form-block">
      <form>
        <div className="form-row">
          <label htmlFor="ocupacion-1" className="form-label label--icon">
            Ocupación 1
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Ocupación del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="ocupacion-1"
            placeholder="Ocupación 1"
            value={personalData.contextoSociocultural?.principalJob || ''}
          />
        </div>
        <div className="form-row">
          <label htmlFor="ocupacion-2" className="form-label label--icon">
            Ocupación 2
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Ocupación del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="ocupacion-2"
            placeholder="Ocupación 2"
            value={personalData.contextoSociocultural?.secondaryJob || ''}
          />
        </div>
        <div className="form-row">
          <label htmlFor="ocupacion-3" className="form-label label--icon">
            Ocupación 3
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Ocupación del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="ocupacion-3"
            placeholder="Ocupación 3"
            value={personalData.contextoSociocultural?.additionalJob || ''}
          />
        </div>
        <div className="form-row">
          <label htmlFor="nivel-educativo" className="form-label label--icon">
            Nivel educativo
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nivel educativo del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="nivel-educativo"
            placeholder="Nivel educativo"
            value={personalData.contextoSociocultural?.educationLevel || ''}
          />
        </div>
        {personalData.contextoSociocultural?.isOriginTown && (
          <div className="form-row">
            <label htmlFor="nivel-educativo" className="form-label label--icon">
              Pueblo originario
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Pueblo originario del usuario</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className="form-control"
              id="nivel-educativo"
              placeholder="Pueblo originario"
              value={personalData.contextoSociocultural?.originTown || ''}
            />
          </div>
        )}
        <div className="form-row">
          <label htmlFor="nivel-educativo" className="form-label label--icon">
            Origen Afrodescendiente
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Origen Afrodescendiente del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="nivel-educativo"
            placeholder="Origen Afrodescendiente"
            value={personalData.contextoSociocultural?.afroDescendant}
          />
        </div>
        <div className="form-row">
          <label htmlFor="vive-con" className="form-label label--icon">
            Vive con
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Vive con</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="vive-con"
            placeholder="Vive con"
            value={personalData.contextoSociocultural?.liveWith}
          />
        </div>
      </form>
    </div>
  );
};

export default InfoDataComponent;
