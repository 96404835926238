import React, { useState, useRef, useCallback } from 'react';
import {TALLERES_DATA} from '../../../utils/talleres'; 
import Tooltip from 'react-bootstrap/Tooltip';
import { Overlay } from 'react-bootstrap';
import ModalTallerEditComponents from '../Modal/ModalTallerEdit/modalTallerEdit.component';


interface TalleresComponentProps {
  mode?: 'derivar' | 'seleccionar' | 'ver-mas';
  onSelectTaller?: (id: number) => void;
  onSelectionChange?: (selectedIds: number[]) => void;
  showAvailableSlotsBadge?: boolean; 
  showStockList?: boolean;
  justifyContentClass?: string;
  cols?: string;
  tallerEstado?: 'proximos' | 'reagendados' | 'concluidos';
}


const TalleresComponent: React.FC<TalleresComponentProps> = ({ 
  mode = 'derivar',
  onSelectionChange,
  showAvailableSlotsBadge = true,
  showStockList = true, 
  justifyContentClass = 'justify-content-center',
  cols = 'col-md-4',
  tallerEstado = 'proximos',
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  
  const handleButtonClick = () => {
    setModalShow(true);
  };
  // Función para determinar la clase del badge según los cupos
  const getBadgeClass = (cupoDisponible: number, cupoTotal: number) => {
    if (cupoDisponible === cupoTotal) {
      return 'badge--blue'; // Todos los cupos disponibles
    } else if (cupoDisponible <= 50) {
      return 'badge--danger'; // 50 o menos cupos disponibles
    } else {
      return 'badge--info'; // Más de 50 cupos disponibles
    }
  };

  // Estado para rastrear los talleres seleccionados
  const [seleccionados, setSeleccionados] = useState<{[id: number]: boolean}>({});
  const [showTooltip, setShowTooltip] = useState<{ [id: number]: boolean }>({});
  const buttonRefs = useRef<Record<number, HTMLElement | null>>({}).current;

  // Manejar el cambio del checkbox
  const handleCheckboxChange = (id: number) => {
    const nuevoEstado = { ...seleccionados, [id]: !seleccionados[id] };
    setSeleccionados(nuevoEstado);
  
    // Aquí, actualizamos cómo se llama a onSelectionChange para evitar el error
    // Primero, convertimos las claves a números y luego filtramos basándonos en el estado actualizado
    const idsSeleccionados = Object.keys(nuevoEstado)
      .map(Number) // Convertimos las claves a números
      .filter(key => nuevoEstado[key]); // Usamos nuevoEstado para obtener el valor actualizado
  
    if (onSelectionChange) {
      onSelectionChange(idsSeleccionados);
    }
  };

  const handleTooltipVisibility = (id: number) => {
    setShowTooltip(prev => ({ ...prev, [id]: !prev[id] }));
  };
  
  const setRef = useCallback((id: number, element: HTMLElement | null) => {
    buttonRefs[id] = element;
  }, []);

  return (
    <>
    <ModalTallerEditComponents
        show={modalShow}
        onHide={() => setModalShow(false)}
    />
      <div className={`row ${justifyContentClass}`}>
        {TALLERES_DATA.map((taller) => (
          <div key={taller.id} className={`item-workshop__col ${cols} col-sm-6 col-12 ${tallerEstado}`}>
            <div className={`item-workshop ${seleccionados[taller.id] ? 'active' : ''}`}>
              <figure className="item-workshop__image">
                <p className={`badge badge-label badge--${taller.tipo === 'online' ? 'success' : 'info'}`}>
                  <span className="icon material-icons-outlined">verified</span>
                  <span><small>{taller.tipo.toUpperCase()}</small></span>
                </p>
                <img src={taller.imagen} alt="Taller" />
              </figure>
              <article>
              {showAvailableSlotsBadge && (
                <p className={`badge badge-label ${getBadgeClass(taller.cupoDisponible, taller.cupoTotal)}`}>
                  <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                  <span>{taller.cupoDisponible} CUPOS DISPONIBLES</span>
                </p>
              )}
                {showStockList && (
                  <ul className="stock-list">
                    <li>
                      <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                    </li>
                    <li>
                      <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                    </li>
                    <li>
                      <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                    </li>
                    <li>
                      <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                    </li>
                    <li>
                      <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                    </li>
                    <li>{taller.cupoTotal} CUPOS</li>
                  </ul>
                )}
               
                <p className="item-workshop__date text-gray600 text-icon mb-0"><i className="icon icon--calendar-line"></i>{taller.fecha}</p>
                <p className="text-icon"><i className="icon icon--clock"></i> {taller.hora}</p>
                {mode === "derivar" && (
                  <button type="button" className="btn btn--type1 ">Derivar aquí</button>
                )}
                {mode === "ver-mas" && (
                  tallerEstado === "concluidos" ? (
                    <div className="d-flex justify-content-center">
                      <a href="/talleres-asistentes" className="btn btn--type4 btn--small-100">Ver listado de asistentes</a>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                     <button
                        type="button"
                        className="btn btn--type1 btn--small"
                        ref={el => setRef(taller.id, el)}
                        onClick={() => handleTooltipVisibility(taller.id)}
                      >
                        Ver más
                      </button>
                      <Overlay
                        show={!!showTooltip[taller.id]}
                        target={buttonRefs[taller.id]}
                        placement={window.innerWidth < 768 ? "bottom" : "right"}
                        rootClose
                        onHide={() => setShowTooltip(prev => ({ ...prev, [taller.id]: false }))}
                      >
                        <Tooltip id={`tooltip-${taller.id}`}>
                         <ul className="tooltip-options">
                          <li>
                            <a href="/edicion-de-taller">
                              <i className="icon icon--edit"></i>
                              Editar taller
                            </a>
                          </li>
                          <li>
                            <a href="/talleres-asistentes">
                              <i className="icon icon--user"></i>
                              Ver asistentes
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="icon icon--whatsapp"></i>
                              Ir a Whatsapp
                            </a>
                          </li>
                          <li>
                            <button type="button" onClick={handleButtonClick}>
                              <i className="icon icon--close"></i>
                              Reagendar
                            </button>
                          </li>
                         </ul>
                        </Tooltip>
                      </Overlay>
                    </div>
                  )
                )}

                {mode === "seleccionar" && (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`taller-${taller.id}`}
                      checked={!!seleccionados[taller.id]}
                      onChange={() => handleCheckboxChange(taller.id)}
                    />
                    <label className="form-check-label" htmlFor={`taller-${taller.id}`}>
                      Seleccionar
                    </label>
                  </div>
                )}
              </article>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TalleresComponent;
