import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';

interface DemographicDataProps {
  user: Partial<IUser>;
}

const DemographicDataComponent: React.FC<DemographicDataProps> = ({ user }) => {
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});

  useEffect(() => {
    if (user) {
      setPersonalData(user);
    }
  }, [user]);
  return (
    <div className="form-block">
      <form>
        <div className="form-row">
          <label htmlFor="age" className="form-label label--icon">
            Edad
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Edad del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="age"
            placeholder="Edad del usuario"
            readOnly={true}
            value={`${
              personalData.datosSociodemograficos?.currentAge || ''
            } años`}
          />
        </div>
        <div className="form-row">
          <label htmlFor="country" className="form-label label--icon">
            Nacionalidad
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nacionalidad del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="country"
            placeholder="Nacionalidad del usuario"
            readOnly={true}
            value={personalData.datosSociodemograficos?.country?.name || ''}
          />
        </div>
        <div className="form-row">
          <label htmlFor="region" className="form-label label--icon">
            Región
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Región del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="region"
            placeholder="Región del usuario"
            readOnly={true}
            value={personalData.datosSociodemograficos?.state?.name || ''}
          />
        </div>
        <div className="form-row">
          <label htmlFor="comuna" className="form-label label--icon">
            Comuna
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Comuna del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="comuna"
            placeholder="Comuna del usuario"
            readOnly={true}
            value={personalData.datosSociodemograficos?.city?.name || ''}
          />
        </div>
      </form>
    </div>
  );
};

export default DemographicDataComponent;
