import React, {useState, useEffect} from 'react';
import DataTable, { TableColumn }  from 'react-data-table-component';
import CustomPagination from '../Paginator/paginator.component'
import { Accordion } from 'react-bootstrap';
interface DataRow {
  id: number;
  ficha: string;
  name: string;
  weeks: string;
  days: string;
  derivada: string;
  apuntes: string;
  status: string;
  url: string;
  isNew: boolean;
  acompanante: string;
}

const AsistenciaTableDoneComponent: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);
  const rowsPerPage = 10;
  const [rows, setRows] = useState<DataRow[]>([
    {
      id: 1,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '10',
      status: 'asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 2,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '',
      status: 'no-asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 3,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      days: '3',
      derivada: 'OLA',
      acompanante: 'Amigas',
      apuntes: '',
      status: 'no-asistio',
      url: '/detalle-usuario',
      isNew: false,
    },
   
  ]);

  const handleNameClick = (id: number) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, isNew: false };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: 'No. Ficha',
      selector: (row: DataRow) => row.ficha,
    },
    {
      name: 'Nombre',
      selector: (row: DataRow) => row.name,
      format: (row: DataRow) => {
        return (
          <a href={row.url} 
             className={`text-purple500 text-uppercase ${row.isNew ? 'new' : ''}`}
             onClick={() => handleNameClick(row.id)}>
            <strong>{row.name}</strong>
          </a>
        );
      }
    },
    {
      name: 'Semanas',
      selector: (row: DataRow) => row.weeks,
      
    },
    {
      name: 'Derivada de',
      selector: (row: DataRow) => row.derivada,
    },
    {
      name: 'Acompañante',
      selector: (row: DataRow) => row.acompanante,
    },
    {
      name: 'Apuntes',
      selector: (row: DataRow) => row.apuntes,
      format: (row: DataRow) => {
       if (row.apuntes != null && row.apuntes != ''){
          return (
            <span className="data-table__number disabled">{row.apuntes}</span>
          )
        } else {
          return (
            <span className="data-table__number number--fail">No dio</span>
          )
        }
      }
    },
    {
      name: 'Estado',
      selector: (row: DataRow) => row.status,
      format: (row: DataRow) => {
        if(row.status === 'asistio') {
          return(
            <span className="text-icon text-gray600">
              <span className="material-icons-outlined text-16 mr16">done</span> Asistió
            </span>
          )
        } else {
          return(
            <span className="text-icon text-red">
              <span className="material-icons-outlined text-16 mr16">close</span> No asistió
            </span>
          )
        }
      }
    },
    
  ];
  
  const conditionalRowStyles = [
    {
      when: (row: DataRow) => row.isNew,
      style: {
        backgroundColor: '#FCEEF2',
      }
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const formatStatus = (status: string) => {
    switch (status) {
      case 'asistio':
        return  <span className="text-icon text-gray600"><span className="material-icons-outlined text-16 mr16">done</span> Asistió</span>;
      default:
        return  <span className="text-icon text-red"><span className="material-icons-outlined text-16 mr16">close</span> No asistió</span>;
    }
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table">
      {!isMobileView ? (
        <DataTable
          columns={columns}
          data={rows}
          conditionalRowStyles={conditionalRowStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationComponent={props => {
            const handleChangePage = (page: number) => {
              console.log('Changing page to', page);
              // Asume que props.onChangePage realmente espera un solo argumento.
              // Esto es para diagnóstico; revisa la consola para ver qué se registra cuando cambias de página.
              (props.onChangePage as (page: number) => void)(page);

            };
          
            return (
              <div className="data-paginator">
                <CustomPagination
                  rowsPerPage={props.rowsPerPage} // eslint-disable-next-line react/prop-types
                  rowCount={props.rowCount} // eslint-disable-next-line react/prop-types
                  onChangePage={handleChangePage}
                  currentPage={currentPage}
                />
              </div>
            );
          }}
          
          onChangePage={page => setCurrentPage(page)}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={rows.length}
        />
      ): (
        <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p><strong>Nombre</strong></p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {rows.map((row, index) => (
                <Accordion.Item eventKey={String(index)} key={row.id}>
                  <Accordion.Header>{row.name}</Accordion.Header>
                  <Accordion.Body>
                    <table>
                      <tr>
                        <td><strong>No. Ficha:</strong></td>
                        <td className="text-center">{row.ficha}</td>
                      </tr>
                      <tr>
                        <td><strong>Semanas:</strong></td>
                        <td className="text-center">{row.weeks}</td>
                      </tr>
                      <tr>
                        <td><strong>Derivada de:</strong></td>
                        <td className="text-center">{row.derivada}</td>
                      </tr>
                      <tr>
                        <td><strong>Acompañante:</strong></td>
                        <td className="text-center">{row.acompanante}</td>
                      </tr>
                      <tr>
                        <td><strong>Apuntes:</strong></td>
                        <td className="text-center">
                          {row.apuntes != null && row.apuntes !== '' ? (
                            <span className="data-table__number disabled">{row.apuntes}</span>
                          ) : (
                            <span className="data-table__number number--fail">No dio</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Estado:</strong></td>
                        <td className="text-center">{formatStatus(row.status)}</td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-center">
                          <a href="/detalle-usuario" className="btn btn--type2">
                            <strong>Ver ficha</strong>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
        </section>
      )}
     
    </div>
  );
};

export default AsistenciaTableDoneComponent;
