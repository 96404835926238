import React, {useState, useEffect} from 'react';
import DataTable, { TableColumn }  from 'react-data-table-component';
import CustomPagination from '../Paginator/paginator.component'
import { Accordion } from 'react-bootstrap';
interface DataRow {
  id: number;
  ficha: string;
  name: string;
  weeks: string;
  derivada: string;
  apuntes: string;
  status: string;
  url: string;
  isNew: boolean;
  acompanante: string;
}

interface TallerTableProps {
  showPaginator?: boolean;
  defaultRowsToShow?: number;
}

const TallerTableComponent: React.FC<TallerTableProps> = ({ showPaginator = true, defaultRowsToShow = 10 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rowsToShow, setRowsToShow] = useState(defaultRowsToShow);
  const [rows, setRows] = useState<DataRow[]>([
    {
      id: 1,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: '10',
      status: 'acompanada',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 2,
      ficha: '12895',
      name: 'Yuya',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: '10',
      status: 'en-espera',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 3,
      ficha: '12896',
      name: 'Yatana',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: 'Pendiente',
      status: 'registrada',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 4,
      ficha: '12896',
      name: 'Yatana',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: 'Pendiente',
      status: 'registrada',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 5,
      ficha: '12896',
      name: 'Yatana',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: 'Pendiente',
      status: 'registrada',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 6,
      ficha: '12896',
      name: 'Yatana',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: 'Pendiente',
      status: 'registrada',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 7,
      ficha: '12896',
      name: 'Yatana',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: 'Pendiente',
      status: 'registrada',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 8,
      ficha: '12896',
      name: 'Yatana',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: 'Pendiente',
      status: 'registrada',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 9,
      ficha: '12896',
      name: 'Yatana',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: 'Pendiente',
      status: 'registrada',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 10,
      ficha: '12896',
      name: 'Yatana',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: 'Pendiente',
      status: 'registrada',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 11,
      ficha: '12896',
      name: 'Yatana',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      acompanante: 'Rafaela',
      apuntes: 'Pendiente',
      status: 'registrada',
      url: '/detalle-usuario',
      isNew: false,
    },
  
  ]);

  const handleNameClick = (id: number) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, isNew: false };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: 'No. Ficha',
      selector: (row: DataRow) => row.ficha,
    },
    {
      name: 'Nombre',
      selector: (row: DataRow) => row.name,
      format: (row: DataRow) => {
        return (
          <a href={row.url} 
             className={`text-purple500 text-uppercase ${row.isNew ? 'new' : ''}`}
             onClick={() => handleNameClick(row.id)}>
            <strong>{row.name}</strong>
          </a>
        );
      }
    },
    {
      name: 'Semanas',
      selector: (row: DataRow) => row.weeks,
    },
    {
      name: 'Derivada de',
      selector: (row: DataRow) => row.derivada,
    },
    {
      name: 'Acompañante',
      selector: (row: DataRow) => row.acompanante,
    },
    {
      name: 'Apuntes',
      selector: (row: DataRow) => row.apuntes,
      format: (row: DataRow) => {
        if (row.apuntes === 'pendiente' || row.apuntes === 'Pendiente') {
          return (
            <span className="data-table__number number--wait">{row.apuntes}</span>
          );
        } else {
          return (
            <span className="data-table__number">{row.apuntes}</span>
          );
        }
       
      }
    },
    {
      name: 'Estado',
      selector: (row: DataRow) => row.status,
      format: (row: DataRow) => {
        if (row.status === 'registrada') {
          return (
            <span className="badge badge--status badge--status-registrada">Registrada</span>
          );
        } else if (row.status === 'en-espera') {
          return (
            <span className="badge badge--status badge--status-info">En espera</span>
          );
        } else if (row.status === 'en-taller') {
          return (
            <span className="badge badge--status badge--status-taller">En taller</span>
          );
        } else if (row.status === 'logrado') {
          return (
            <span className="badge badge--status badge--status-done">Logrado</span>
          );
        } else if (row.status === 'seguimiento') {
          return (
            <span className="badge badge--status badge--status-info">Seguimiento</span>
          );
        } else if (row.status === 'no-logrado') {
          return (
            <span className="badge badge--status badge--status-info">No logrado</span>
          );
        }  else if (row.status === 'acompanada') {
          return (
            <span className="badge badge--status badge--status-acompanada">Acompañada</span>
          );
        } else {
          return (
            <span className="badge badge--status badge--status-info">{row.status}</span>
          );
        }
       
      }
    },
    
  ];
  
  const conditionalRowStyles = [
    {
      when: (row: DataRow) => row.isNew,
      style: {
        backgroundColor: '#FCEEF2',
      }
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const formatStatus = (status: string) => {
    switch (status) {
      case 'registrada':
        return <span className="badge badge--status badge--status-registrada">Registrada</span>;
      case 'en-espera':
        return <span className="badge badge--status badge--status-info">En espera</span>;
      case 'en-taller':
        return <span className="badge badge--status badge--status-taller">En taller</span>;
      case 'logrado':
        return <span className="badge badge--status badge--status-done">Logrado</span>;
      case 'seguimiento':
        return <span className="badge badge--status badge--status-info">Seguimiento</span>;
      case 'no-logrado':
        return <span className="badge badge--status badge--status-info">No logrado</span>;
      case 'acompanada':
        return <span className="badge badge--status badge--status-acompanada">Acompañada</span>;
      default:
        return <span className="badge badge--status badge--status-info">{status}</span>;
    }
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table">
       {!isMobileView ? (
        <DataTable
          columns={columns}
          data={rows.slice(0, rowsToShow)}
          conditionalRowStyles={conditionalRowStyles}
          pagination={showPaginator} 
          paginationComponentOptions={paginationComponentOptions}
          paginationComponent={props => {
            const handleChangePage = (page: number) => {
              console.log('Changing page to', page);
              // Asume que props.onChangePage realmente espera un solo argumento.
              // Esto es para diagnóstico; revisa la consola para ver qué se registra cuando cambias de página.
              (props.onChangePage as (page: number) => void)(page);

            };
          
            return (
              <div className="data-paginator">
                <CustomPagination
                  rowsPerPage={props.rowsPerPage} // eslint-disable-next-line react/prop-types
                  rowCount={props.rowCount} // eslint-disable-next-line react/prop-types
                  onChangePage={handleChangePage}
                  currentPage={currentPage}
                />
              </div>
            );
          }}
          
          onChangePage={page => setCurrentPage(page)}
          paginationPerPage={rowsToShow}
          paginationTotalRows={rows.length}
        />

       ): (
        <section className="data-table-mobile">
        <div className="data-table-mobile__header">
          <p><strong>Nombre</strong></p>
        </div>
          <Accordion className="data-table-mobile__accordion">
            {rows.slice(0, rowsToShow).map((row, index) => (
                <Accordion.Item eventKey={String(index)} key={row.id}>
                  <Accordion.Header>{row.name}</Accordion.Header>
                  <Accordion.Body>
                    <table>
                      <tr>
                        <td><strong>No. Ficha:</strong></td>
                        <td className="text-center">{row.ficha}</td>
                      </tr>
                      <tr>
                        <td><strong>Semanas:</strong></td>
                        <td className="text-center">{row.weeks}</td>
                      </tr>
                      <tr>
                        <td><strong>Derivada de:</strong></td>
                        <td className="text-center">{row.derivada}</td>
                      </tr>
                      <tr>
                        <td><strong>Acompañante:</strong></td>
                        <td className="text-center">{row.acompanante}</td>
                      </tr>
                      <tr>
                        <td><strong>Apuntes:</strong></td>
                        <td className="text-center">
                          {row.apuntes === 'pendiente' || row.apuntes === 'Pendiente' ? (
                              <span className="data-table__number number--wait">{row.apuntes}</span>
                            ) : (
                              <span className="data-table__number">{row.apuntes}</span>
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Estado:</strong></td>
                        <td className="text-center">{formatStatus(row.status)}</td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-center">
                          <a href="/detalle-usuario" className="btn btn--type2">
                            <strong>Ver ficha</strong>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
        </section>

       ) }
      
    </div>
  );
};

export default TallerTableComponent;
