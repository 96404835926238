import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import TallerCreateComponent from '../../../../components/Auth/Taller/create.component';


const TalleresCreateScreen: React.FC = () => {

  return (
    <>
    <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
           
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a href="/talleres" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
              </div>
              <div className="col-md-5 col-12">
                <h1>Registro de taller</h1>
                <p className="text-20">Completa estos datos para poder registrar un nuevo taller</p>
                <TallerCreateComponent />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img src="/assets/images/illustrations/remember.png" alt="Editar taller" />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
  </>
  );
};

export default TalleresCreateScreen;