import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import NotificationsComponent from '../../../../components/Elements/Notifications/notifications.component';
import AsistenciaTableDoneComponent from '../../../../components/Elements/DataTable/asistenciaTableDone.component';

const TalleresChecklistDoneScreen: React.FC = () => {
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/talleres" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-12 mb-5">
                <h1 className="mb-3">Taller “No estás Sola” del 23/03/2023</h1>
                <p className="h5 text-regular">
                  Registro de asistencia de mujeres
                </p>
                <p className="text-purple500">
                  <strong>Asistieron 40 de 50 mujeres</strong>
                </p>
                <p>Impartido por: Rita</p>
                <div className="col-12 mb-3">
                  <NotificationsComponent
                    message={
                      'Recuerda que por cuidado y privacidad de la información, <strong>una vez concluido el taller se eliminará</strong> de tu sesión en 30 días posteriores'
                    }
                    user={{}}
                    status="warning"
                  />
                </div>
                <p className="mb-2">Buscar</p>

                <div className="form-row form--search">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Buscar"
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
              </div>
              <div className="col-12 mb-5">
                <h4 className="mb-4">Mujeres que asistieron al taller</h4>
                <AsistenciaTableDoneComponent />
              </div>
              <div className="d-flex justify-content-center col-12">
                <a href="/talleres" className="btn btn--type2">
                  Regresar al catálogo de talleres
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TalleresChecklistDoneScreen;
